.questionOption .table_box .el-form-item__content {
  line-height: 0;
}
.questionOption .el-input {
  width: 50% !important;
}
.questionOption .sortUp {
  width: 19px;
  height: 19px;
  display: inline-block;
  background: url("../../../assets/sortUp.png") no-repeat;
  background-size: cover;
  cursor: pointer;
}
.questionOption .sortDown {
  width: 19px;
  height: 19px;
  display: inline-block;
  background: url("../../../assets/sortDown.png") no-repeat;
  background-size: cover;
  cursor: pointer;
}
.delImg {
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  background: url("../../../assets/close_.png") no-repeat;
  background-size: cover;
}
